/**
 * Const with params element
 * Hero type2 component
 * Display the hero type 2: h1 + text at right
 */
/* Import section */
import React from "react"
import "../styles/components/herotype2.scss"

/* Declaration function */
const Herotype2 = ( { h1title, textIntro, colorBar} ) => {
  return (

      <div className="wrap-hero-type2">
        <div className="wrap-title">
        <h1 dangerouslySetInnerHTML={{__html:h1title}} />
        </div>
        <div className="wrap-box-intro">
          <div className="box-intro">
            <div className={`separator-bar ${colorBar}`}></div>
            <div className="text-intro" dangerouslySetInnerHTML={{ __html:textIntro}} />
          </div>
        </div>
      </div>
  )
}

/* Export function */
export default Herotype2

