/**
 * Faqs Telehealth page Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Blockcategoryfaq from "../components/blockcategoryfaq"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Navinternalfaq from "../components/navinternalfaq"
import Seo from "../components/seo"
import "../styles/pages/faqs.scss"


/* Declaration function */
const Faqspage = ({ data }) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageFaqstelehealth

  //Array for Internal nav for faq categories
  let arrItemsnavinternalfaq = []
  var dataCategory = []
  dataCategory = data.categoriesFaqtelehealth
  dataCategory.distinct.map( (categoryFaq)  => (
    arrItemsnavinternalfaq.push({itemNav: categoryFaq, anchorNav:"#catfaq"})
  ))

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "contact1"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        datastructured="faqs"
        datastructuredfaq="faq-telehealth"
      />

      {/* Hero section */}
      <section className="section-hero-faqs top-section">
        <Herotype2 h1title={dataAcf.titleHeroFaqTelehealth} textIntro={dataAcf.textHeroFaqTelehealth} colorBar="white" />
      </section>

      {/*FAQs details section */}
      <section className="section-details-faqs">
        <div className="wrap-category-faq">
          <Navinternalfaq arrItemsNav={arrItemsnavinternalfaq}/>
          <div className="box-list-faq">
            {dataCategory.distinct.map((categoryFaq, index) =>
              <Blockcategoryfaq key={index} typeFaq="telehealth" idCatecoryFaq={`catfaq-${index}`} categoryFaq={categoryFaq} />
            )}
          </div>
        </div>
      </section>

      {/* Banner CTA Faqs */}
      <Bannerwithcta
        classBanner="section-banner-faq"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default Faqspage

//QUERY GRAPHSQL ON POST
export const query = graphql`
    query {
      wpPage(slug: {eq: "faqs-telehealth"}) {
        slug
        acfPageFaqstelehealth {
          textHeroFaqTelehealth
          titleHeroFaqTelehealth
        }
        seo {
          title
          metaDesc
        }
      }
      categoriesFaqtelehealth: allWpFaqtelehealth {
        distinct(field: catsfaqstelehealth___nodes___name)
      }
      options: wpPage(slug: {eq: "options-website"}) {
        acfPageOptions {
          repeaterBannerCta {
            ctaLabelBanner
            ctaLinkBanner
            textBannerCta
            titleBannerCta
            topicBannerCta
          }
        }
      }
    }
`
