/**
 * Class element
 * Accordion Faq component
 * Display an accordion for FAQs
 */
/* Import section */
import React, { Component } from "react"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState} from "react-accessible-accordion"
import { IconContext } from "react-icons"
import { BsPlus, BsDash } from "react-icons/bs"
import "../styles/components/accordionfaq.scss"

/* Declaration class */
class Accordionfaq extends Component {
  constructor(props) {
    super(props)
    this.state = {
      indexActive: null
    }
  }

  onChangeAccordion(index) {
    if(index === this.state.indexActive) {
      index = null
    }
    this.setState({
      indexActive: index
    })
  }

  render () {
    const arrItems = this.props.arrItems
    return (
      <Accordion className="accordion accordion-faq" allowZeroExpanded>
        {arrItems.map((item, index) => (
          <AccordionItem key={`accordion-${item.title}-${index}`} uuid={item.index} className={(item.index === this.state.indexActive) ? "accordion__item active-accordion":"accordion__item"} onClick={() => this.onChangeAccordion(index)}>
            <AccordionItemHeading>
              <AccordionItemButton >
                  <h3 dangerouslySetInnerHTML={{__html:item.title}} />
                <AccordionItemState>
                  {({ expanded }) =>  (expanded ?
                    (<span className="span-chevron active-accordion">
                      <IconContext.Provider value={{ className: "icon-chevron" }}>
                        <BsDash />
                      </IconContext.Provider>
                    </span>) :
                    (<span className="span-chevron">
                      <IconContext.Provider value={{ className: "icon-chevron" }}>
                        <BsPlus />
                      </IconContext.Provider>
                    </span>))}
                </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="box-content-accordion post-content" dangerouslySetInnerHTML={{ __html:item.text}}/>
            </AccordionItemPanel>
          </AccordionItem>
          )
        )}
      </Accordion>
    )
  }
}


/* Export function */
export default Accordionfaq

