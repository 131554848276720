/**
 * Const with a param and useStaticQuery element
 * Category Block Faq component
 * Display for the category the block of a question with his answer in accordion
 */
/* Import section */
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Accordionfaq from "../components/accordionfaq"
import Titledecorative from "../components/titledecorative"
import "../styles/components/blockcategoryfaq.scss"

/* Declaration function */
const Blockcategoryfaq = ({ typeFaq, idCatecoryFaq, categoryFaq }) => {
  const data = useStaticQuery(
    graphql`
      query {
        faqhh: allWpFaq {
          nodes {
            acfPostFaq {
              response
            }
            title
            catsfaqs {
              nodes {
                name
              }
            }
          }
        }
        faqtelehealth: allWpFaqtelehealth  {
          nodes {
            acfPostFaqTelehealth {
              responseTelehealth
            }
            title
            catsfaqstelehealth {
              nodes {
                name
              }
            }
          }
        }
      }
    `
  )

  let arrFaq = []
  if(typeFaq === "hellohealth") {
    //Filter the faq hh of the category
    var qrFaqOfCathh = data.faqhh.nodes.filter(function (faqOfCathh) {
      return faqOfCathh.catsfaqs.nodes[0].name === categoryFaq
    })
    qrFaqOfCathh.map((faqhh, index) =>
      arrFaq.push({index: index, title: faqhh.title, text: faqhh.acfPostFaq.response})
    )
  }else if(typeFaq === "telehealth") {
    //Filter the faq telehealth of the category
    var qrFaqOfCattelehealth = data.faqtelehealth.nodes.filter(function (faqOfCattelehealth) {
      return faqOfCattelehealth.catsfaqstelehealth.nodes[0].name === categoryFaq
    })
    qrFaqOfCattelehealth.map((faqtelehealth, index) =>
      arrFaq.push({index: index, title: faqtelehealth.title, text: faqtelehealth.acfPostFaqTelehealth.responseTelehealth})
    )
  }


  return (
    <div key={categoryFaq} id={idCatecoryFaq} className="block-category-faq">
      <Titledecorative title={categoryFaq} />
      <Accordionfaq arrItems={arrFaq} />
    </div>
  )
}

/* Export function */
export default Blockcategoryfaq
