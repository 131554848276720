/**
 * Class element
 * Nav internal faq component
 * Display a nav internal with anchor
 */
/* Import section */
import PropTypes from "prop-types"
import React, { Component } from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import ScrollMenu from "react-horizontal-scrolling-menu"
import { IconContext } from "react-icons"
import { BsChevronRight, BsChevronLeft } from "react-icons/bs"
import "../styles/components/navinternalfaq.scss"

/* Declaration class */
class Navinternalfaq extends Component {

  constructor(props) {
    super(props)
    var list = this.props.arrItemsNav
    this.menu = null
    this.state = {
      activeAnchor: 0,
      alignCenter: false,
      alignOnResize: false,
      clickWhenDrag: false,
      dragging: false,
      hideArrows: true,
      hideSingleArrow: true,
      itemsCount: list.length,
      scrollToSelected: false,
      selected: "menuinternalfaq-0",
      stickyNav: false,
      translate: 0,
      transition: 0.4,
      wheel: false,
      width: 0,
    }

    this.menuItems = Menu(list.slice(0, list.length), this.state.selected)
  }

  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
    this.handleWindowSizeChange()
    window.addEventListener('resize', this.handleWindowSizeChange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange)
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleWindowSizeChange = () => {
    if(window.innerWidth !== this.state.width) {
      this.setState({
        width: window.innerWidth,
      })
    }
  }

  handleScroll = () => {
    var htmlsection = [].slice.call((document.getElementsByTagName("html")))
    var navinternal = [].slice.call((document.getElementsByClassName("wrap-nav-internal-faq")))
    var itemNavInternal = [].slice.call((document.getElementsByClassName("box-list-faq")))
    var offSetTopitemNavInternal = itemNavInternal[0].offsetTop
    var offsetHeightitemNavInternal = itemNavInternal[0].offsetHeight
    var offSetNav = offSetTopitemNavInternal - 200
    var sumOffSetHeight = offSetNav + offsetHeightitemNavInternal
    var arrChild = Array.from(itemNavInternal[0].childNodes)
    let arrChildOffsetTop = []
    var activeStateAnchor = this.state.activeAnchor
    var stickyStateNav = true

    arrChild.map((child, index) => {
      return arrChildOffsetTop.push([index, child.offsetTop, child.offsetTop+child.offsetHeight])
    })

    if((offSetNav < document.body.scrollTop &&  document.body.scrollTop < sumOffSetHeight) || (offSetNav < document.documentElement.scrollTop && document.documentElement.scrollTop < sumOffSetHeight)) {
      var currentScrollTopSticky = document.documentElement.scrollTop + 120
      var arrChildFilter = arrChildOffsetTop.filter(function(element){
        return element[1] < currentScrollTopSticky && currentScrollTopSticky < element[2]
      })

      if(Array.isArray(arrChildFilter) && arrChildFilter.length) {
        activeStateAnchor = arrChildFilter[0][0]
      }
      navinternal[0].classList.add("sticky")
      htmlsection[0].classList.add("scrollsmooth")
    } else {
      stickyStateNav = false
      navinternal[0].classList.remove("sticky")
      htmlsection[0].classList.remove("scrollsmooth")
    }
    this.setState({
      activeAnchor: activeStateAnchor,
      stickyNav: stickyStateNav,
      selected: `menuinternalfaq-${activeStateAnchor}`,
      clickWhenDrag: true
    })
  }

  /* Horizontal slider menu functions */
  onUpdate = ({ translate }) => {
    this.setState({ translate })
  }

  onSelect = key => {
    this.setState({ selected: key })
  }

  componentDidUpdate(prevProps, prevState) {
    const { alignCenter } = prevState;
    const { alignCenter: alignCenterNew } = this.state;
    if (alignCenter !== alignCenterNew) {
      this.menu.setInitial()
    }
  }

  render () {
    const {
      alignCenter,
      alignOnResize,
      clickWhenDrag,
      hideArrows,
      dragging,
      hideSingleArrow,
      scrollToSelected,
      selected,
      translate,
      transition,
      wheel
    } = this.state;

    const menu = this.menuItems

    return (
      <div className="wrap-nav-internal-faq">
        <ScrollMenu
          alignCenter={alignCenter}
          alignOnResize={alignOnResize}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          clickWhenDrag={clickWhenDrag}
          data={menu}
          dragging={dragging}
          hideArrows={hideArrows}
          hideSingleArrow={hideSingleArrow}
          onSelect={this.onSelect}
          onUpdate={this.onUpdate}
          ref={el => (this.menu = el)}
          scrollToSelected={scrollToSelected}
          selected={selected}
          transition={+transition}
          translate={translate}
          wheel={wheel}
        />
      </div>
    )
  }
}

/* Export function */
export default Navinternalfaq

/* Items menu unctions */
const MenuItem = ({ item, index, selected }) => {
  var classCurrent = (selected) ? "current" : ""
  return (
    <div className={`menu-item ${classCurrent}`} >
      <AnchorLink offset='130' href={`${item.anchorNav}-${index}`}><span>{item.itemNav}</span></AnchorLink>
    </div>
    )
}

export const Menu = (list, selected) =>
  list.map((el, index) => {
    return <MenuItem item={el} key={`menuinternalfaq-${index}`} index={index} selected={selected}  />
})


/* Arrows nav unctions */
const Arrow = ({ arrowDirection, className }) => {
  return <div className={`arrow-nav-internal ${className}`}>
    <IconContext.Provider value={{ className: "icon-arrow" }}>
      {arrowDirection === "right" ? (
        <BsChevronRight />
      ) : (
        <BsChevronLeft />
      )}
    </IconContext.Provider>
  </div>;
};
Arrow.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export const ArrowLeft = Arrow({ arrowDirection: "left", className: "arrow-prev" });
export const ArrowRight = Arrow({ arrowDirection: "right", className: "arrow-next" });
